<template>
  <v-dialog
    :value="value"
    :fullscreen="fullscreen"
    :content-class="fillHeight && !fullscreen ? 'fill-height' : ''"
    persistent
    scrollable
    :width="width"
    @input="close"
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
          color="primary"
          dark
          dense
          flat
        >
          <v-row align="center">
            <v-col v-if="left" cols="1" class="text-center">
              <slot name="left" />
            </v-col>
            <v-col v-if="left" cols="10" class="text-center">
              <v-toolbar-title v-text="title" />
            </v-col>
            <v-col
              v-else
              cols="10"
              offset="1"
              class="text-center"
            >
              <v-toolbar-title v-text="title" />
            </v-col>
            <v-col cols="1">
              <v-btn small icon @click="close">
                <v-icon>close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </v-card-title>
      <v-card-text :class="{'pa-0' : noContentPadding}">
        <v-container :class="{'pa-0' : noContentPadding}">
          <slot />
        </v-container>
      </v-card-text>
      <v-footer v-if="hasFooter" padless>
        <v-toolbar dense>
          <slot name="footer" />
        </v-toolbar>
      </v-footer>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AgModalContainer',
  props: {
    left: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'auto',
    },
    noContentPadding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasFooter () {
      return !!this.$slots.footer
    },
  },
  methods: {
    close () {
      this.$emit('input', false)
      this.$emit('close')
    },
  },
}
</script>
