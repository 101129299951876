<template>
  <v-dialog
    v-model="opened"
    persistent
    max-width="290"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>
    <v-card>
      <v-card-title class="headline justify-center" v-text="title" />
      <v-card-text v-if="textConfirmation">
        <p class="text-center">
          {{ message }}
        </p>
        <v-text-field
          v-model="inputTextConfirmation"
          dense
          label="Confirmation"
          outlined
        />
      </v-card-text>
      <v-card-text v-else class="text-center pre-formatted" v-text="message" />
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="disabledCancel"
          :loading="loadingCancel"
          color="primary"
          outlined
          @click="cancel"
          v-text="cancelLabel"
        />
        <v-btn
          :disabled="disabled"
          :loading="loadingConfirm"
          :color="validationBtnColor"
          @click="confirm"
          v-text="confirmLabel"
        />
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AgConfirmDialog',
  props: {
    value: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    cancelLabel: {
      type: String,
      default: 'Cancel',
    },
    confirmLabel: {
      type: String,
      default: 'Confirm',
    },
    validationBtnColor: {
      type: String,
      default: 'primary',
    },
    textConfirmation: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    opened: false,
    loadingConfirm: false,
    loadingCancel: false,
    inputTextConfirmation: '',
  }),
  computed: {
    disabledCancel () {
      return this.loadingConfirm || this.loadingCancel || !this.opened
    },
    disabled () {
      return this.disabledCancel || !this.canValidate
    },
    canValidate () {
      if (!this.textConfirmation) return true
      if (this.textConfirmation.toLowerCase() !== this.inputTextConfirmation.toLowerCase()) {
        return false
      }
      return true
    },
  },
  watch: {
    value: {
      immediate: true,
      async handler (val) {
        this.opened = val
      },
    },
    opened: {
      immediate: true,
      async handler () {
        this.inputTextConfirmation = ''
        // Remove previous focus for avoid click on open button
        document.activeElement.blur()
        if (this.opened) {
          window.document.addEventListener('keyup', this.onKeyupEvent)
        }
        else {
          window.document.removeEventListener('keyup', this.onKeyupEvent)
        }
      },
    },
  },
  methods: {
    close () {
      this.$emit('input', false)
      this.opened = false
    },
    async cancel () {
      this.loadingCancel = true
      if (this.$listeners.cancel) {
        await this.$listeners.cancel()
      }
      this.close()
      this.loadingCancel = false
    },
    async confirm () {
      this.loadingConfirm = true
      if (this.$listeners.confirm) {
        await this.$listeners.confirm()
      }
      this.close()
      this.loadingConfirm = false
    },
    onKeyupEvent (event) {
      if (event.code === 'Enter') {
        this.confirm()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .pre-formatted {
    white-space: pre-wrap;
  }
</style>
